import {apolloModel} from "~/services/gql/vender/model";
import SelectTagMastersQuery from "~/gql/setting/tag/SelectTagMasters.gql";
import SelectTagMastersWithoutPaginationQuery from "~/gql/setting/tag/SelectTagMastersWithoutPagination.gql";
import CreateTagMasterMutation from "~/gql/setting/tag/CreateTagMaster.gql";
import EditTagMasterMutation from "~/gql/setting/tag/EditTagMaster.gql";
import DeleteTagMastersMutation from "~/gql/setting/tag/DeleteTagMasters.gql";

export const tag = {

  /**
   * Get tag masters Action
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectTagMastersWithoutPaginationAction(app, variables) {
    const data = await apolloModel.query(app, SelectTagMastersWithoutPaginationQuery, variables);

    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Get tag masters Action
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectTagMastersAction(app, variables) {
    const data = await apolloModel.query(app, SelectTagMastersQuery, variables);

    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Create tag master action
   * @param app
   * @param variables
   * @return {Promise<Promise<unknown>|Promise<never>>}
   */
  async createTagMasterAction(app, variables) {
    const data = await apolloModel.mutate(app, CreateTagMasterMutation, variables);

    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Edit tag master action
   * @param app
   * @param variables
   * @return {Promise<Promise<unknown>|Promise<never>>}
   */
  async editTagMasterAction(app, variables) {
    const data = await apolloModel.mutate(app, EditTagMasterMutation, variables);

    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete tag masters action
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async deleteTagMasters(app, variables) {
    const data = await apolloModel.query(app, DeleteTagMastersMutation, variables);

    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
