import {apolloModel} from "~/services/gql/vender/model";
import SelectVendorsQuery from "~/gql/shop/SelectVendors.gql";
import SelectShopQuery from "~/gql/shop/SelectShop.gql";
export const shop = {
  /**
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectVendorsAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectVendorsQuery, variables);
    return data;
  },

  /**
   * 
   * @param app 
   * @param variables 
   * @returns {Promise<{}>}
   */
  async SelectShopAction(app, variables){
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectShopQuery, variables);
    return data;
  }
}
