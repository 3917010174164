import {apolloModel} from "~/services/gql/vender/model";
import SelectCustomerQuery from "~/gql/customer/SelectCustomer.gql";
import SelectCustomerHistoryPropertiesQuery from "~/gql/customer/SelectCustomerHistoryProperties.gql";
import SelectPropertiesManagementQuery from "~/gql/customer/HistoryPropertiesRelation/SelectPropertiesManagement.gql";
import SelectPropertyLandQuery from "~/gql/customer/HistoryPropertiesRelation/SelectPropertyLand.gql";
import SelectPropertySevedImagesQuery from "~/gql/customer/HistoryPropertiesRelation/SelectPropertySevedImages.gql";
import SelectParentPropertyQuery from "~/gql/customer/HistoryPropertiesRelation/SelectParentProperty.gql";
import SelectBasicPropertyQuery from "~/gql/customer/HistoryPropertiesRelation/SelectBasicProperty.gql";
import SelectCustomerProjectQuery from "~/gql/customer/SelectCustomerProject.gql";
import SelectCustomerAgreementQuery from "~/gql/customer/SelectCustomerAgreement.gql";
import SelectCustomerResponseQuery from "~/gql/customer/SelectCustomerResponse.gql";
import SelectCustomerHistoriesQuery from "~/gql/customer/SelectCustomerHistories.gql";
import SelectCustomerHistoryStatusQuery from "../../gql/customer/SelectHistoryStatus.gql"
import SelectCustomerHistoriesPropertyRelationQuery from "~/gql/customer/CustomerHistoriesRelation/SelectCustomerHistoriesPropertyRelation.gql";
import SelectCustomerHistoriesMailRelationQuery from "~/gql/customer/CustomerHistoriesRelation/SelectCustomerHistoriesMailRelation.gql";
import SelectCustomerHistoriesLineRelationQuery from "~/gql/customer/CustomerHistoriesRelation/SelectCustomerHistoriesLineRelation.gql";
import SelectCustomerHistoriesKibouRelationQuery from "~/gql/customer/CustomerHistoriesRelation/SelectCustomerHistoriesKibouRelation.gql";
import SelectCustomerHistoriesFileRelationQuery from "~/gql/customer/CustomerHistoriesRelation/SelectCustomerHistoriesFileRelation.gql";
import SelectCustomerHistoriesAnalyticQuery from "~/gql/customer/SelectCustomerHistoriesAnalytic.gql";
import SelectSideCustomerHistoriesQuery from "~/gql/customer/SelectSideCustomerHistories.gql";
import SelectClientRelationQuery from "~/gql/customer/CustomerHistoriesInChargeRelation/SelectClientRelation.gql";
import SelectDesiredConditionsQuery from "~/gql/customer/CustomerHistoriesInChargeRelation/SelectDesiredConditions.gql";
import SelectMailReceiveQuery from "~/gql/customer/CustomerHistoriesInChargeRelation/SelectMailReceive.gql";
import CreateCustomerHistoryMutation from "~/gql/customer/CreateCustomerHistory.gql";
import CreateCustomerHistoriesMutation from "~/gql/customer/CreateCustomerHistories.gql";
import UpdateCustomerHistoryMutation from "~/gql/customer/UpdateCustomerHistory.gql";
import DeleteCustomerHistoryMutation from "~/gql/customer/DeleteCustomerHistory.gql";
import SelectCustomerTasyaPropertiesQuery from "~/gql/customer/SelectCustomerTasyaProperties.gql";
import CreateCustomerTasyaPropertyMutation from "~/gql/customer/CreateCustomerTasyaProperty.gql";
import {mergeData} from "~/helper/common";

export const customerHistory = {

  /**
   * 顧客（詳細）データ取得処理
   * @param app
   * @param variables
   */
  async selectCustomerAction(app, {client}) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerQuery, {client});
    return data;
  },

  /**
   * @param app
   * @param client
   * @returns {Promise<Awaited<unknown>[]>}
   */
  async selectCustomerHistoryPropertiesAction(app, client) {
    // GraphQLから顧客のデータ取得
    const promiseData = [
      apolloModel.query(app, SelectCustomerHistoryPropertiesQuery, client),
      apolloModel.query(app, SelectPropertiesManagementQuery, client),
      apolloModel.query(app, SelectBasicPropertyQuery, client),
      apolloModel.query(app, SelectParentPropertyQuery, client),
      apolloModel.query(app, SelectPropertyLandQuery, client),
      apolloModel.query(app, SelectPropertySevedImagesQuery, client),
    ];
    return Promise.all(promiseData).catch(error => { throw error }).then(responses => { return mergeData(responses); }
    );
  },

  /**
   * @param app
   * @param client
   * @returns {Promise<void>}
   */
  async selectCustomerProjectAction(app, {client}) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerProjectQuery, {client});
    return data;
  },

  /**
   * @param app
   * @param client
   * @returns {Promise<void>}
   */
  async selectCustomerAgreementAction(app, {seiyaku}) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerAgreementQuery, {seiyaku});
    return data;
  },

  /**
   * @param app
   * @param client
   * @returns {Promise<void>}
   */
  async selectCustomerResponseAction(app, {client}) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerResponseQuery, {client});
    return data;
  },

  /**
   * 顧客履歴データ取得処理
   * @param app
   * @param variables
   */
  async selectCustomerHistoriesAction(app, variables) {
    //GraphQLから顧客のデータ取
    const promiseData = [
      apolloModel.query(app, SelectCustomerHistoriesQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesFileRelationQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesKibouRelationQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesLineRelationQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesMailRelationQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesPropertyRelationQuery, variables),
    ]
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses);
      })
      .catch(error => {
        throw error
      });
  },

  /**
   * @param app
   * @param variables
   */
  async selectCustomerHistoryStatusQueryAction(app, variables) {
    const data = await apolloModel.query(app, SelectCustomerHistoryStatusQuery, variables);
    return data
  },

  /**
   * 顧客履歴データ取得処理
   * @param app
   * @param variables
   */
  async selectCustomerHistoriesAnalyticAction(app, variables) {
    // GraphQLから顧客のデータ取
    const data = await apolloModel.query(app, SelectCustomerHistoriesAnalyticQuery, variables);
    return data;
  },

  /**
   * 画面右側　顧客履歴データ取得処理
   * @returns {Promise<[]>}
   * @param app
   * @param variables
   */
  async selectSideCustomerHistoriesAction(app, variables) {
    // GraphQLから顧客データ取得
    const promiseData = [
      apolloModel.query(app, SelectSideCustomerHistoriesQuery, variables),
      apolloModel.query(app, SelectClientRelationQuery, variables),
      apolloModel.query(app, SelectDesiredConditionsQuery, variables),
      apolloModel.query(app, SelectMailReceiveQuery, variables),
    ]
    return Promise.all(promiseData).catch(error => { throw error }).then(responses => { return mergeData(responses) });
  },

  /**
   * アクション登録
   * @param app
   * @param variables
   */
  async createCustomerHistoryAction(app, {ClientHistoryInput, kibous, files, properties}) {
    // GraphQLへ反響更新リクエスト
    const variables = {ClientHistoryInput, kibous, files, properties};
    delete variables.ClientHistoryInput.files;
    delete variables.ClientHistoryInput.kibous;
    const data = await apolloModel.mutate(app, CreateCustomerHistoryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * アクション一括登録
   * @param app
   * @param variables
   */
  async createCustomerHistoriesAction(app, {createClientHistoryInput, createCondition}) {
    const variables = {createClientHistoryInput, createCondition};

    // GraphQLへ反響更新リクエスト
    const data = await apolloModel.mutate(app, CreateCustomerHistoriesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * アクション更新
   * @param app
   * @param variables
   */
  async updateCustomerHistoryAction(app, {updateClientHistoryInput, kibous, files, properties}) {
    // GraphQLへ反響更新リクエスト
    const variables = {updateClientHistoryInput, kibous, files, properties};
    delete variables.updateClientHistoryInput.clientRelation;
    delete variables.updateClientHistoryInput.clientHistoryFiles;
    delete variables.updateClientHistoryInput.files;
    delete variables.updateClientHistoryInput.clientHistoryKibous;
    delete variables.updateClientHistoryInput.kibous;
    delete variables.updateClientHistoryInput.clientHistoryProperties;
    delete variables.updateClientHistoryInput.__typename;
    delete variables.updateClientHistoryInput.mailClientRelation;
    delete variables.updateClientHistoryInput.mailReceive;
    delete variables.updateClientHistoryInput.lineClientRelation;
    delete variables.updateClientHistoryInput.lineReceiveRelation;
    const data = await apolloModel.mutate(app, UpdateCustomerHistoryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * アクション削除
   * @param app
   * @param variables
   */
  async deleteCustomerHistoryAction(app, {clientHistories, adminId}) {
    const variables = {clientHistories, adminId}
    const data = await apolloModel.mutate(app, DeleteCustomerHistoryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * @param app
   * @param client
   * @returns {Promise<void>}
   */
  async selectCustomerTasyaPropertiesAction(app, client) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerTasyaPropertiesQuery, client);
    return data;
  },

  /**
   * @param app
   * @param tasyaProperty
   * @returns {Promise<void>}
   */
  async createCustomerTasyaPropertyAction(app, tasyaProperty) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.mutate(app, CreateCustomerTasyaPropertyMutation, tasyaProperty);
    return data;
  },
}
