import {apolloModel} from "~/services/gql/vender/model";
import SelectMaisokuTemplateUserSettingQuery from "~/gql/maisokuTemplateUserSetting/SelectMaisokuTemplateUserSetting.gql"
import UpsertMaisokuTemplateUserSettingMutation from "~/gql/maisokuTemplateUserSetting/UpsertMaisokuTemplateUserSetting.gql"

export const templateUserSetting = {
  /**
   * Select Maisoku template user setting
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectTemplateUserSetting(app, variables) {
    // GraphQLからマイソクテンプレート設定のデータ取得
    const data = await apolloModel.query(app, SelectMaisokuTemplateUserSettingQuery, variables);
    return data;
  },
  /**
   * Create Maisoku template user setting
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async upsertTemplateUserSetting(app, variables) {
    // GraphQLへマイソクテンプレート設定の更新・登録リクエスト
    const data = await apolloModel.mutate(app, UpsertMaisokuTemplateUserSettingMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
