import {apolloModel} from "~/services/gql/vender/model";
import constMedia from "~/const/media";
import SelectMediaQuery from "~/gql/media/SelectMedia.gql";
import SelectMediasQuery from "~/gql/media/SelectMedias.gql";
import CreateMediaMutation from "~/gql/media/CreateMedia.gql";
import UpdateMediaMutation from "~/gql/media/UpdateMedia.gql";
import DeleteMediasMutation from "~/gql/media/DeleteMedias.gql";

export const media = {

  /**
   * 媒体一詳細取得処理
   * @returns Promise<String> 媒体
   * @param app
   * @param baitaiId
   */
  async selectMediaAction(app, baitaiId) {
    // GraphQLへ媒体取得リクエスト
    const variables = {baitaiId: parseInt(baitaiId)};
    return await apolloModel.query(app, SelectMediaQuery, variables);
  },

  /**
   * Get Media Data In Gql
   *
   * @param app
   * @param variables {shopId, baitaiType, baitaiCategory, keyword}
   * @returns {Promise<void>}
   */
  async getMediaDataGql(app, variables) {
    // GraphQLから媒体一覧のデータ取得
    return await apolloModel.query(app, SelectMediasQuery, variables);
  },

  /**
   * Create Media Gql
   *
   * @param app
   * @param variables {medium}
   * @returns Promise<*>
   */
  async createMediaAction(app, {medium}) {
    // GraphQLへ媒体一作成リクエスト
    const variables = {medium: medium};
    const data = await apolloModel.mutate(app, CreateMediaMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Media Gql
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async updateMediaGql(app, variables) {
    // GraphQLへ反響作成リクエスト
    const data = await apolloModel.mutate(app, UpdateMediaMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete media records
   *
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async deleteMediasAction(app, variables) {
    const data = await apolloModel.mutate(app, DeleteMediasMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
