import {apolloModel} from "~/services/gql/vender/model";
import SelectProjectsQuery from "~/gql/project/SelectProjects.gql";
import SelectProjectQuery from "~/gql/project/SelectProject.gql";
import SelectProjectUpdateHistoriesQuery from "~/gql/project/SelectProjectUpdateHistories.gql";
import SideProjectsQuery from "~/gql/project/SideProjectsQuery.gql";
import CreateDesiredConditionMutation from "~/gql/project/CreateDesiredCondition.gql";
import UpdateDesiredConditionMutation from "~/gql/project/UpdateDesiredCondition.gql";
import SelectMonthlyProjectsQuery from "~/gql/project/SelectMonthlyProjects.gql";
import SelectDetailDesiredConditionQuery from "~/gql/project/SelectDetailDesiredCondition.gql";
import DeleteDesiredConditionMutation from "~/gql/project/DeleteDesiredCondition.gql";

export const project = {
  /**
   * 案件一覧のデータ取得処理
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectProjectListAction(app, variables) {
    // GraphQLから案件一覧のデータ取得
    const data = await apolloModel.query(app, SelectProjectsQuery, variables);
    return data;
  },

  /**
   * 画面右側 ステータス別案件数の取得
   * @returns void
   * @param app
   * @param variables
   */
  async sideProjectListAction(app, variables) {
    // GraphQLから案件一覧のデータ取得
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SideProjectsQuery, variables);
    return data;
  },

  /**
   * 案件の登録処理
   * @param app
   * @param desiredCondition
   * @returns {Promise<unknown>}
   */
  async createDesiredConditionAction(app, {desiredCondition}) {
    // GraphQLへ案件作成リクエスト
    const variables = {desiredCondition};
    delete variables.desiredCondition.updated_user;
    const data = await apolloModel.mutate(app, CreateDesiredConditionMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 案件の更新処理
   * @param app
   * @param desiredConditions
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateDesiredConditionAction(app, {desiredConditions}) {
    // GraphQLへ案件更新リクエスト
    const variables = {desiredConditions};
    //案件部分
    delete variables.desiredConditions.created_user;
    delete variables.desiredConditions.created_at;
    delete variables.desiredConditions.deleted;
    delete variables.desiredConditions.updated_at;
    //表示用のclientRelationを削除
    delete variables.desiredConditions.clientRelation;
    variables.desiredConditions.desiredConditionAreas?.forEach(function (e) {
      delete e.__typename;
      delete e.todohukenRelation;
      delete e.sikucyosonRelation;
      delete e.ensenRelation;
      delete e.startEkiRelation;
      delete e.endEkiRelation;
      if (e.ensen_id === '') e.ensen_id = null;
      if (e.start_eki_id === '') e.start_eki_id = null;
      if (e.end_eki_id === '') e.end_eki_id = null;
      e.desiredConditionAreaGakkous?.forEach(function (i) {
        delete i.__typename;
        delete i.surroundingRelation;
        delete i.category
      });
      e.desiredConditionAreaCyos?.forEach(function (i) {
        delete i.__typename;
        delete i.cyoRelation;
      });
      e.desiredConditionAreaEkis?.forEach(function (i) {
        delete i.__typename;
        delete i.ekiRelation;
      });
    });

    variables.desiredConditions.desiredConditionFiles?.forEach(function (e) {
      delete e.created_at;
      delete e.created_user;
      delete e.kibou;
      delete e.updated_at;
      delete e.updated_user;
    });

    variables.desiredConditions.desiredConditionDoukis?.forEach(function (e) {
      delete e.doukiRelation;
    });
    variables.desiredConditions.desiredConditionKoukokuKatsudous?.forEach(function (e) {
      delete e.koukokuKatsudouRelation;
    });
    variables.desiredConditions.desiredConditionKozos?.forEach(function (e) {
      delete e.kozoRelation;
    });
    variables.desiredConditions.desiredConditionMadoris?.forEach(function (e) {
      delete e.madoriRelation;
    });
    variables.desiredConditions.desiredConditionPropertyRelatedPersons?.forEach(function (e) {
      delete e.propertyRelatedPerson;
    });

    if (variables.desiredConditions.matchingCondition) {
      variables.desiredConditions.matchingCondition.matchingConditionAreas?.forEach(function (e) {
        delete e.__typename;
        delete e.todohukenRelation;
        delete e.sikucyosonRelation;
        delete e.startEkiRelation;
        delete e.endEkiRelation;
        delete e.ensenRelation;
        delete e.kibou_area;
        if (e.ensen_id === '') e.ensen_id = null;
        if (e.start_eki_id === '') e.start_eki_id = null;
        if (e.end_eki_id === '') e.end_eki_id = null;
        e.matchingConditionAreaGakkous?.forEach(function (i) {
          delete i.__typename;
          delete i.surroundingRelation;
          delete i.category;
          delete i.kibou_area_gakkou;
        });
        e.matchingConditionAreaCyos?.forEach(function (i) {
          delete i.__typename;
          delete i.cyoRelation;
          delete i.kibou_area_cyo;
        });
        e.matchingConditionAreaEkis?.forEach(function (i) {
          delete i.__typename;
          delete i.ekiRelation;
          delete i.kibou_area_eki;
        });
      });
      variables.desiredConditions.matchingCondition.matchingConditionKozos?.forEach(function (e) {
        delete e.__typename;
        delete e.kibou_kozo;
        delete e.kozoRelation;
      });
      variables.desiredConditions.matchingCondition.matchingConditionMadoris?.forEach(function (e) {
        delete e.__typename;
        delete e.kibou_madori;
        delete e.madoriRelation;
      });
    }
    const data = await apolloModel.mutate(app, UpdateDesiredConditionMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 案件の一括更新処理
   * @returns void
   * @param app
   * @param variables
   */
  async updateDuplicateDesiredConditionAction(app, variables) {
    const data = await apolloModel.mutate(app, UpdateDesiredConditionMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 案件詳細データ取得
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async initDetailDesiredConditionAction(app, variables) {
    // GraphQLから案件データ取得
    let data = await apolloModel.query(app, SelectDetailDesiredConditionQuery, variables);
    return data;
  },

  /**
   * 案件の削除処理
   * @returns void
   * @param app
   * @param variables
   */
  async deleteDesiredConditionAction(app, variables) {
    // 案件削除リクエスト
    const data = await apolloModel.mutate(app, DeleteDesiredConditionMutation, variables);
    return data;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectMonthlyProjectsAction(app, variables) {
    variables = {
      shopId: app.$store.state.app.loginUser.shop,
      targetDate: variables.targetDate,
      isAgreement: variables.isAgreement,
    };
    const {monthlyDesireConditionStatus: data} = await apolloModel.query(app, SelectMonthlyProjectsQuery, variables);
    return data;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectAgreement(app, variables) {
    const data = await apolloModel.query(app, SelectProjectQuery, variables);
    return data?.desiredCondition;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectProjectHistory(app, variables) {
    const data = await apolloModel.query(app, SelectProjectUpdateHistoriesQuery, variables);
    return data?.desiredCondition;
  },
}
