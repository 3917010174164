import {apolloModel} from "~/services/gql/vender/model";
import SelectStepMailsQuery from "~/gql/stepMail/SelectStepMails.gql";
import SelectStepMailQuery from "~/gql/stepMail/SelectStepMail.gql";
import CreateStepMailSettingMutation from "~/gql/stepMail/CreateStepMail.gql";
import UpdateStepMailSettingMutation from "~/gql/stepMail/UpdateStepMail.gql";
import DeleteStepMailSettingsMutation from "~/gql/stepMail/DeleteStepMails.gql";
import constStepMail from "~/const/stepMail";

export const stepMail = {

  /**
   * Get List Step Mail Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectStepMailsAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constStepMail.pagination.pageSize});
    const data = await apolloModel.query(app, SelectStepMailsQuery, variables);
    return data;
  },

  /**
   * Select Step Mail Action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectStepMailAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectStepMailQuery, variables);
    return data;
  },

  /**
   * Create Step Mail Action
   * @param app
   * @param stepMailSettingInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createStepMailAction(app, stepMailSettingInput) {
    // GraphQLへ反響更新リクエスト
    const variables = {createStepMailSetting: stepMailSettingInput};
    const data = await apolloModel.mutate(app, CreateStepMailSettingMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Step Mail Action
   * @param app
   * @param stepMailSettingInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateStepMailAction(app, stepMailSettingInput) {
    const variables = {updateStepMailSetting: stepMailSettingInput};
    const data = await apolloModel.mutate(app, UpdateStepMailSettingMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Step Mails Action
   * @param app
   * @param adminId
   * @param mails
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async deleteStepMailsAction(app, {adminId, stepMailSettings}) {
    // 顧客削除リクエスト
    const variables = {adminId, stepMailSettings: stepMailSettings};
    const data = await apolloModel.mutate(app, DeleteStepMailSettingsMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
