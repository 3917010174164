import {apolloModel} from "~/services/gql/vender/model";
import SelectStaffsQuery from "~/gql/staff/SelectStaffs.gql";
import CreateStaffMutation from "~/gql/staff/CreateStaff.gql";
import SelectStaffQuery from "~/gql/staff/SelectStaff.gql";
import SelectShopsQuery from "~/gql/staff/SelectShopList.gql";
import UpdateStaffMutation from "~/gql/staff/UpdateStaff.gql";
import DeleteStaffsMutation from "~/gql/staff/DeleteStaffs.gql";

export const staff = {
  /**
   * Get List Staff Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectStaffsAction(app, variables) {
    return await apolloModel.query(app, SelectStaffsQuery, variables);
  },
  /**
   * Create Staff Action
   * @param app
   * @param shopUserInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createStaffAction(app, shopUserInput) {
    // GraphQLへ反響更新リクエスト
    const variables = {shopUserInput: shopUserInput};
    delete variables.shopUserInput.userShopRelation;
    delete variables.shopUserInput.password_confirmation;
    const data = await apolloModel.mutate(app, CreateStaffMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Get staff detail Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectStaffAction(app, variables) {
    return await apolloModel.query(app, SelectStaffQuery, variables);
  },

  /**
   * Get shop list by companyId
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectShopListByCompanyAction(app, variables) {
    return await apolloModel.query(app, SelectShopsQuery, variables);
  },

  /**
   * Update Staff Action
   * @param app
   * @param variables
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateStaffAction(app, variables) {
    const data = await apolloModel.mutate(app, UpdateStaffMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Remove Staff Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async deleteStaffsAction(app, variables) {
    return await apolloModel.mutate(app, DeleteStaffsMutation, variables);
  },
}
