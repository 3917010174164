import {apolloModel} from "~/services/gql/vender/model";
import SelectDailyActivitiesQuery from "~/gql/activity/SelectDailyActivities.gql";
import UpsertActivitiesMutation from "~/gql/activity/UpsertActivities.gql";
import constActivity from '~/const/activity';

export const activity = {
  /**
   * Get List Activities Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectDailyActivitiesAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constActivity.pagination.pageSize});
    return await apolloModel.query(app, SelectDailyActivitiesQuery, variables);
  },

  /**
   * Upsert activities action
   * @param app
   * @param activitiesInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async upsertActivitiesAction(app, activitiesInput) {
    const variables = {activitiesInput: activitiesInput};
    const data = await apolloModel.mutate(app, UpsertActivitiesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  }
}
