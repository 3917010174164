import {apolloModel} from "~/services/gql/vender/model";
import SelectFileExportQuery from "~/gql/fileExport/SelectFileExport.gql";
import CreateFileExportMutation from "~/gql/fileExport/CreateFileExport.gql";

export const fileExport = {
  /**
   * ファイル出力の詳細
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectFileExportAction(app, variables) {
    // GraphQLファイルのエクスポートリストを取得する
    return await apolloModel.query(app, SelectFileExportQuery, variables);
  },

  /**
   * ファイルのエクスポート登録
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async createFileExportAction(app, variables) {
    // GraphQLファイルのエクスポート登録
    const data = await apolloModel.mutate(app, CreateFileExportMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
