import {apolloModel} from "~/services/gql/vender/model";
import constMailTemplate from "~/const/mailTemplate";
import SelectMailTemplatesQuery from "~/gql/mailTemplate/SelectMailTemplates.gql";
import CreateMailTemplateMutation from "~/gql/mailTemplate/CreateMailTemplate.gql";
import UpdateMailTemplateMutation from "~/gql/mailTemplate/UpdateMailTemplate.gql";
import DeleteMailTemplatesMutation from "~/gql/mailTemplate/DeleteMailTemplates.gql";

export const mailTemplate = {

  /**
   * Get List Mail Template Action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectMailTemplatesAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constMailTemplate.pagination.pageSize});
    const data = await apolloModel.query(app, SelectMailTemplatesQuery, variables);
    return data;
  },

  /**
   * Create Mail Template Action
   * @param app
   * @param mailTemplateInput
   * @returns Promise<*>
   */
  async createMailTemplateAction(app, mailTemplateInput) {
    // GraphQLへ反響更新リクエスト
    const variables = {createMailTemplate: mailTemplateInput};
    const data = await apolloModel.mutate(app, CreateMailTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Mail Template Action
   * @param app
   * @param mailTemplateInput
   * @returns Promise<*>
   */
  async updateMailTemplateAction(app, mailTemplateInput) {
    // GraphQLへ反響更新リクエスト
    const variables = {updateMailTemplateInput: mailTemplateInput};
    delete variables.updateMailTemplateInput.created_user;
    delete variables.updateMailTemplateInput.shop_user;
    const data = await apolloModel.mutate(app, UpdateMailTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Mail Templates Action
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async deleteMailTemplatesAction(app, variables) {
    // 顧客削除リクエスト
    const data = await apolloModel.mutate(app, DeleteMailTemplatesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
