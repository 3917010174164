import {apolloModel} from "~/services/gql/vender/model";
import constCustomer from "~/const/customer.js";
import SelectExportPDFQuery from "~/gql/customer/SelectExportPDF.gql";
import CreateExportPDFMutation from "~/gql/customer/CreateExportPDF.gql";
import SelectCustomersQuery from "~/gql/customer/SelectCustomers.gql";
import SelectModalCustomersQuery from "~/gql/customer/SelectModalCustomers.gql";
import SelectModalDesiredConditionsByCustomerQuery from "~/gql/customer/SelectModalDesiredConditionsByCustomer.gql";
import SelectCustomerQuery from "~/gql/customer/SelectCustomer.gql";
import CreateClientMutation from "~/gql/customer/CreateClient.gql";
import UpdateClientMutation from "~/gql/customer/UpdateClient.gql";
import DeleteCustomersMutation from "~/gql/customer/DeleteCustomers.gql";
import SelectCustomerTagsQuery from "~/gql/customer/SelectCustomerTags.gql";
import SelectCustomerUpdateHistoriesQuery from "~/gql/customer/SelectCustomerUpdateHistories.gql";
import SelectSimilarCustomerListQuery from "~/gql/customer/SimilarCustomerRelation/SelectSimilarCustomerList.gql";
import SelectCustomerAddressQuery
  from "~/gql/customer/SimilarCustomerRelation/ParentCustomerRelation/SelectCustomerAddress.gql";
import SelectCustomerHistoriesQuery
  from "~/gql/customer/SimilarCustomerRelation/ParentCustomerRelation/SelectCustomerHistories.gql";
import SelectCustomerHousematesQuery
  from "~/gql/customer/SimilarCustomerRelation/ParentCustomerRelation/SelectCustomerHousemates.gql";
import SelectCustomerSyokaiQuery
  from "~/gql/customer/SimilarCustomerRelation/ParentCustomerRelation/SelectCustomerSyokai.gql";
import {mergeData} from "~/helper/common";

export const customer = {
  /**
   * 顧客一覧のデータ取得処理
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectCustomersAction(app, variables) {
    // GraphQLから顧客一覧のデータ取得
    variables = Object.assign(variables);
    delete variables.memberRegistered;
    delete variables.restrict;
    const data = await apolloModel.query(app, SelectCustomersQuery, variables);
    return data;
  },

  /**
   * リストから顧客をフィルタリングする
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectSelectedCustomersAction(app, variables) {
    // GraphQLから顧客一覧のデータ取得
    variables = Object.assign(variables, {pageSize: constCustomer.pagination.pageSize20});
    const data = await apolloModel.query(app, SelectCustomersQuery, variables);
    return data;
  },

  /**
   * 顧客（詳細）データ取得処理
   * @param app
   * @param variables
   * @returns {Promise<*[]>}
   */
  async selectCustomerAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerQuery, variables);
    return data;
  },

  async selectCustomerUpdateHistoryAction(app, {client}) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectCustomerUpdateHistoriesQuery, {client});
    return data;
  },

  /**
   * 顧客作成処理
   * @returns Promise<String> 顧客ID
   * @param app
   * @param variables {client}
   */
  async createClientAction(app, {client, desiredCondition}) {
    // GraphQLへ顧客作成リクエスト
    const variables = {client, desiredCondition};

    //不要な項目削除
    delete variables.desiredCondition.updated_user;
    //希望条件
    variables.desiredCondition.desiredConditionAreas.forEach(function (e) {
      e.desiredConditionAreaGakkous?.forEach(function (i) {
        delete i.category
      });
    });
    //マッチング条件
    variables.desiredCondition.matchingCondition.matchingConditionAreas.forEach(function (e) {
      e.matchingConditionAreaGakkous?.forEach(function (i) {
        delete i.category;
      });
    });
    const data = await apolloModel.mutate(app, CreateClientMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 顧客更新処理
   * @returns Promise<T>
   * @param app
   * @param variables
   */
  async updateClientAction(app, variables) {
    delete variables.client.created_user;
    const data = await apolloModel.mutate(app, UpdateClientMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 顧客の削除処理
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async deleteCustomerAction(app, variables) {
    // 顧客削除リクエスト
    const data = await apolloModel.mutate(app, DeleteCustomersMutation, variables);
    return data;
  },

  /**
   * モーダル内の顧客一覧の取得処理
   * @param app
   * @param variables
   * @param isOnlyCustomer
   * @returns {Promise<*>}
   */
  async selectModalCustomerList(app, variables, isOnlyCustomer) {
    // GraphQLから顧客一覧のデータ取得
    variables = Object.assign(variables, {pageSize: constCustomer.customerListCount});
    const promiseData = [
      apolloModel.query(app, SelectModalCustomersQuery, variables),
    ]
    if (!isOnlyCustomer) {
      promiseData.push(apolloModel.query(app, SelectModalDesiredConditionsByCustomerQuery, variables));
    }
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses);
      })
      .catch(error => {
        throw error;
      });
  },

  /**
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectCustomerTagsAction(app, variables) {
    // GraphQLから顧客一覧のデータ取得
    const data = await apolloModel.query(app, SelectCustomerTagsQuery, variables);
    return data;
  },


  /**
   * @param app
   * @param fileId
   * @returns {Promise<*>}
   */
  async selectExportPDF(app, {fileId}) {
    // GraphQLPDFの出力
    const data = await apolloModel.query(app, SelectExportPDFQuery, {fileId});
    return data;
  },

  /**
   * @param app
   * @param fileExportInput
   * @returns {Promise<*>}
   */
  async createExportPDF(app, {fileExportInput}) {
    const variables = {fileExportInput};
    // GraphQL出力PDFを作成する
    const data = await apolloModel.mutate(app, CreateExportPDFMutation, variables);
    return data;
  },

  /**
   * 名寄せ顧客リストの取得処理
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async similarCustomersAction(app, variables) {
    // GraphQLから名寄せ顧客一覧のデータ取得
    const promiseData = [
      apolloModel.query(app, SelectSimilarCustomerListQuery, variables),
      apolloModel.query(app, SelectCustomerAddressQuery, variables),
      apolloModel.query(app, SelectCustomerHistoriesQuery, variables),
      apolloModel.query(app, SelectCustomerHousematesQuery, variables),
      apolloModel.query(app, SelectCustomerSyokaiQuery, variables),
    ]
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses);
      })
      .catch(error => {
        throw error;
      });
  },
}
