import {AppLogic} from "~/logics/app-logic";
import constMessages from "~/const/messages";
import {GraphQLError} from 'graphql';

const fn = () => undefined;
const fetchPolicy = 'no-cache';

export const apolloModel = {
  /**
   * GraphQLクエリのベース
   * @param app
   * @param query
   * @param variables
   * @param recall
   * @returns {Promise<void>}
   */
  async query(app, query, variables, recall = 1) {
    return await app.$apolloProvider.defaultClient.query({query, variables, fetchPolicy})
      .then(({data}) => {
        return data;
      })
      .catch(e => {
        if(e.message === "GraphQL error: Execution timed out." && recall) {
          return this.query(app, query, variables, 0);
        } else {
          AppLogic.setSystemMessage(app, {
            isShow: true,
            isFlash: true,
            type: 'error',
            message: constMessages.api.default
          });
          // エラー時、トップへスクロール
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      });
  },

  /**
   * GraphQLミューテーションのベース
   * @param app
   * @param mutation
   * @param variables
   * @param update
   * @returns {Promise<void>}
   */
  async mutate(app, mutation, variables, update = fn) {
    return await app.$apolloProvider.defaultClient.mutate({mutation, variables})
      .then(({data}) => data)
      .catch(e => {
        const graphQLErrors = e.graphQLErrors[0];
        const message = graphQLErrors.message ?? constMessages.api.default;
        AppLogic.setSystemMessage(app, {
          isShow: true,
          isFlash: true,
          type: 'error',
          message: message
        });
        // エラー時、トップへスクロール
        window.scrollTo({top: 0, behavior: 'smooth'});
        // Throw error for logics file
        throw new Error(message);
        // TODO: implement throw new GraphQLErrors(message, null, null, null, null, null, {test: 'test'});
      });
  },
}
