export default (context) => {
  return {
    httpEndpoint: process.env.VUE_APP_API_URL,
    httpLinkOptions: {
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    },
    inMemoryCacheOptions: {
      addTypename: false,
    },
  }
}
