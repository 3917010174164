import {apolloModel} from "~/services/gql/vender/model";
import constMailLog from "~/const/mailLog.js";
import constCustomer from "~/const/customer.js";
import SelectMailQuery from "~/gql/mail/SelectMail.gql";
import SelectMailsQuery from "~/gql/mail/SelectMails.gql";
import SelectCustomersQuery from "~/gql/mail/SelectCustomers.gql";
import SelectMailClientsQuery from "~/gql/mail/SelectMailClients.gql";
import CreateMailMutation from "~/gql/mail/CreateMail.gql";
import CreateMailBulkMutation from "~/gql/mail/CreateMailBulk.gql";
import UpdateMailMutation from "~/gql/mail/UpdateMail.gql";
import DeleteMailsMutation from "~/gql/mail/DeleteMails.gql";
import UpdateMatchingMailMutation from "~/gql/mail/UpdateMatchingMail.gql";

export const mail = {
  /**
   * selectMailsAction
   * Recall one time after throw error
   * @param app
   * @param variables
   * @param recall
   * @return {Promise<*|undefined>}
   */
  async selectMailsAction(app, variables, recall = 1) {
    // GraphQLから顧客一覧のデータ取得
    variables = Object.assign(variables, {pageSize: constMailLog.pagination.pageSize});
    const data = await apolloModel.query(app, SelectMailsQuery, variables);
    return data;
  },

  /**
   * Select mail action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectMailAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectMailQuery, variables);
    return data;
  },

  /**
   * Select mailClients action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectMailClientsAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectMailClientsQuery, variables);
    return data;
  },
  /**
   * Create Mail Action
   * @param app
   * @param mail
   */
  async createMailAction(app, mail) {
    // GraphQLへ反響更新リクエスト
    const variables = {createMail: mail};
    const data = await apolloModel.mutate(app, CreateMailMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Create Mail Bulk
   * @param app
   * @param mailBulk
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createMailBulkAction(app, mailBulk) {
    // GraphQLへ反響更新リクエスト
    const variables = {createMailBulk: mailBulk};
    delete variables.createMailBulk.mail.mailBlindCarbonCopies;
    delete variables.createMailBulk.mail.mailCarbonCopies;
    delete variables?.createMailBulk?.searchCondition?.memberRegistered;
    const data = await apolloModel.mutate(app, CreateMailBulkMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Mail Action
   * @param app
   * @param mail
   * @returns Promise<*>
   */
  async updateMailAction(app, mail) {
    // GraphQLへ反響更新リクエスト
    const variables = {updateMail: mail};
    variables.updateMail.mailClients?.forEach(function (e) {
      delete e.clientRelation;
    });
    variables.updateMail.mailProperties?.forEach(function (e) {
      delete e.propertiesManagement;
    });
    const data = await apolloModel.mutate(app, UpdateMailMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Mail Action
   * @param app
   * @param adminId
   * @param mails
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async deleteMailAction(app, {adminId, mails}) {
    // 顧客削除リクエスト
    const variables = {adminId, mails: mails};
    const data = await apolloModel.mutate(app, DeleteMailsMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * select all customer ids
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectCustomerIdsAction(app, variables) {
    // GraphQLから顧客一覧のデータ取得
    variables = Object.assign(variables, {pageSize: constCustomer.pagination.pageSize});
    delete variables.memberRegistered;
    delete variables.restrict;
    const data = await apolloModel.query(app, SelectCustomersQuery, variables);
    return data;
  },

   /**
   * Update Matching Mail Action
   * @param app
   * @param matchingMailInput
   * @returns Promise<*>
   */
   async updateMatchingMailAction(app, matchingMailInput) {
    const variables = {updateMail: matchingMailInput};
    const data = await apolloModel.mutate(app, UpdateMatchingMailMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
