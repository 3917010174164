import {apolloModel} from "~/services/gql/vender/model";
import SelectAchievementQuery from "~/gql/achievement/SelectAchievement.gql";
import CreateAchievementMutation from "~/gql/achievement/CreateAchievement.gql";
import UpdateAchievementMutation from "~/gql/achievement/UpdateAchievement.gql";
import SelectAchievementsQuery from "~/gql/achievement/SelectAchievements.gql";
import DeleteAchievementsMutation from "~/gql/achievement/DeleteAchievements.gql";

import constAchievement from "~/const/achievement";

export const achievement = {

  /**
   * Get List Achievement Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAchievementsAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constAchievement.pagination.pageSize});
    const data = await apolloModel.query(app, SelectAchievementsQuery, variables);
    return data;
  },

  /**
   * Select Step Mail Action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectAchievementAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectAchievementQuery, variables);
    return data;
  },

  /**
   * Create Achievement Action
   * @param app
   * @param achievementInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createAchievementAction(app, achievementInput) {
    // GraphQLへ反響更新リクエスト
    const variables = {createAchievementInput: achievementInput};
    const data = await apolloModel.mutate(app, CreateAchievementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Achievement Action
   * @param app
   * @param achievementInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateAchievementAction(app, achievementInput) {
    const variables = {updateAchievementInput: achievementInput};
    const data = await apolloModel.mutate(app, UpdateAchievementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Achievements Action
   * @param app
   * @param variables
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async deleteAchievementsAction(app, variables) {
    // 顧客削除リクエスト
    return await apolloModel.mutate(app, DeleteAchievementsMutation, variables);
  },
}
