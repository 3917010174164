import {apolloModel} from "~/services/gql/vender/model";
import SelectMaisokuTemplateQuery from "~/gql/maisokuTemplate/SelectMaisokuTemplate.gql"
import SelectMaisokuTemplatesQuery from "~/gql/maisokuTemplate/SelectMaisokuTemplates.gql"
import CreateMaisokuTemplateMutation from "~/gql/maisokuTemplate/CreateMaisokuTemplate.gql"
import UpdateMaisokuTemplateMutation from "~/gql/maisokuTemplate/UpdateMaisokuTemplate.gql"
import DeleteMaisokuTemplatesMutation from "~/gql/maisokuTemplate/DeleteMaisokuTemplates.gql"
import constMaisokuTemplate from "~/const/maisokuTemplate.js";

export const template = {
  /**
   * Select Maisoku template
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectTemplate(app, variables) {
    // GraphQLからマイソクテンプレートのデータ取得
    const data = await apolloModel.query(app, SelectMaisokuTemplateQuery, variables);
    return data;
  },
  /**
   * Select Maisoku templates
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectTemplates(app, variables) {
    // GraphQLからマイソクテンプレートのデータ取得
    variables = Object.assign(variables, {pageSize: constMaisokuTemplate.pagination.pageSize});
    const data = await apolloModel.query(app, SelectMaisokuTemplatesQuery, variables);
    return data;
  },
  /**
   * Create Maisoku template user setting
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async createTemplate(app, variables) {
    // GraphQLへマイソクテンプレートの登録リクエスト
    const data = await apolloModel.mutate(app, CreateMaisokuTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
  /**
   * Update Maisoku template user setting
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async updateTemplate(app, variables) {
    // GraphQLへマイソクテンプレートの登録リクエスト
    const data = await apolloModel.mutate(app, UpdateMaisokuTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
  /**
   * Delete Maisoku template user setting
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async deleteTemplates(app, variables) {
    // GraphQLへマイソクテンプレートの削除リクエスト
    const data = await apolloModel.mutate(app, DeleteMaisokuTemplatesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
