import {apolloModel} from "~/services/gql/vender/model";
import SelectStaffMessagesQuery from "~/gql/staffMessage/SelectStaffMessages.gql";
import SelectIndividualStaffMessagesQuery from "~/gql/staffMessage/SelectIndividualStaffMessages.gql";
import CreateStaffMessageMutation from "~/gql/staffMessage/CreateStaffMessage.gql";
import ConfirmStaffMessageMutation from "~/gql/staffMessage/ConfirmStaffMessage.gql";

export const staffMessage = {
  /**
   * スタッフメッセージ一覧を取得する
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectStaffMessagesAction(app, variables) {
    // GraphQLスタッフメッセージ一覧を取得する
    return await apolloModel.query(app, SelectStaffMessagesQuery, variables);
  },

  /**
   * 個々のスタッフとのメッセージ一覧を取得する
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectIndividualStaffMessagesAction(app, variables) {
    // GraphQL個々のスタッフとのメッセージ一覧を取得する
    return await apolloModel.query(app, SelectIndividualStaffMessagesQuery, variables);
  },

  /**
   * スタッフメッセージを作成する
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async createStaffMessageAction(app, variables) {
    // GraphQLスタッフメッセージを作成する
    const data = await apolloModel.mutate(app, CreateStaffMessageMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * スタッフメッセージを確認
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async confirmStaffMessageAction(app, variables) {
    // GraphQLスタッフメッセージを作成する
    const data = await apolloModel.mutate(app, ConfirmStaffMessageMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
