import {apolloModel} from "~/services/gql/vender/model";
import SelectInquiryQuery from "~/gql/dashboard/SelectInquiries.gql";
import SelectCustomerHistoriesQuery from "~/gql/dashboard/SelectCustomerHistories.gql";
import SelectClientRelationQuery from "~/gql/dashboard/CustomerHistoriesRelation/SelectClientRelation.gql";
import SelectDesiredConditionsQuery from "~/gql/dashboard/CustomerHistoriesRelation/SelectDesiredConditions.gql";
import SelectMailReceiveQuery from "~/gql/dashboard/CustomerHistoriesRelation/SelectMailReceive.gql";
import SelectAllActionsCustomerHistoriesQuery from "~/gql/dashboard/SelectAllActionsCustomerHistories.gql";
import SelectAllNitijisCustomerHistoriesQuery from "~/gql/dashboard/SelectAllNitijisCustomerHistories.gql";
import SelectMonthlyInquiryQuery from "~/gql/dashboard/SelectMonthlyInquiry.gql";
import UpdateCustomerHistoryMutation from "~/gql/dashboard/UpdateCustomerHistory.gql";
import {mergeData} from "~/helper/common";

export const dashboard = {

  /**
   * 反響データ取得処理
   * @returns void
   * @param app
   * @param variables {adminId}
   */
  async getInquiryListAction(app, variables) {
    // GraphQLから反響データ取得
    const data = await apolloModel.query(app, SelectInquiryQuery, variables);
    return data;
  },

  /**
   * 月別反響一覧のデータ取得処理
   * @returns void
   * @param app
   * @param date
   */
  async selectMonthlyInquiryAction(app, variables) {
    // GraphQLから月別反響一覧の初期データ取得
    const data = await apolloModel.query(app, SelectMonthlyInquiryQuery, variables);
    return data;
  },

  /**
   * 顧客データ取得処理
   * @returns {Promise<[]>}
   * @param app
   * @param variables
   */
  async selectDashboardCustomerHistoryAction(app, variables) {
    // GraphQLから顧客データ取得
    const promiseData = [
      apolloModel.query(app, SelectClientRelationQuery, variables),
      apolloModel.query(app, SelectDesiredConditionsQuery, variables),
      apolloModel.query(app, SelectMailReceiveQuery, variables),
      new Promise((resolve, reject) => {
        // Wrap the heavy query in a new promise
        const timer = setTimeout(() => {
          reject(new Error('Timeout occurred for SelectCustomerHistoriesQuery'));
        }, 10000);

        apolloModel.query(app, SelectCustomerHistoriesQuery, variables)
          .then((response) => {
            clearTimeout(timer);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timer);
            reject(error);
          });
      }),
    ]
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses)
      })
      .catch(error => {
        throw error
      });
  },

  /**
   *
   * @param app
   * @param variables
   * @returns {Promise<*|*[]>}
   */
  async selectDashboardAllActionsCustomerHistoryAction(app, variables) {
    // GraphQLから顧客データ取得
    const data = await apolloModel.query(app, SelectAllActionsCustomerHistoriesQuery, variables);
    return data ? data : [];
  },

  /**
   *
   * @param app
   * @param variables
   * @returns {Promise<*|*[]>}
   */
  async selectDashboardAllNitijisCustomerHistoryAction(app, variables) {
    // GraphQLから顧客データ取得
    const data = await apolloModel.query(app, SelectAllNitijisCustomerHistoriesQuery, variables);
    return data ? data : [];
  },

  /**
   * Update Dashboard Customer History Action
   * @param app
   * @param updateCustomerHistoryInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateDashboardCustomerHistoryAction(app, {updateCustomerHistoryInput}) {
    // GraphQLから顧客データ取得
    const variables = {updateCustomerHistoryInput};
    const data = await apolloModel.mutate(app, UpdateCustomerHistoryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  // /**TODO　変更
  //  * 物件の取得処理
  //  * @returns void
  //  * @param app
  //  * @param variables {azukariShubetsu, companyName, createdAtEnd, createdAtStart, cyoCd, ekiId, ensenId, kakakuEnd, kakakuStart, property, shop, sikucyosonCd, todohukenCd, type}
  //  */
  // async changePropertyListAction(app, variables) {
  //   // loading表示開始
  //   await app.$store.dispatch('dashboard/propertyListLoadingStart');
  //
  //   // GraphQLへ市区町村取得リクエスト
  //   const data = await apolloModel.query(app, SelectPropertyListQuery, variables);
  //
  //   // Storeに削除後の反響データをセット
  //   await app.$store.dispatch('dashboard/setPropertyList', data?.searchProperties);
  //
  //   // loading表示停止
  //   await app.$store.dispatch('dashboard/propertyListLoadingEnd');
  // },
  //
  // /**
  //  * 物件一覧の削除処理
  //  * @returns void
  //  * @param app
  //  */
  // async removePropertyListAction(app) {
  //   // Storeに削除後の反響データをセット
  //   await app.$store.dispatch('dashboard/setPropertyList', []);
  // },
}
