import {apolloModel} from "~/services/gql/vender/model";
import SelectDocumentTemplatesQuery from "~/gql/documentTemplate/SelectDocumentTemplates.gql";
import CreateDocumentTemplateMutation from "~/gql/documentTemplate/CreateDocumentTemplate.gql";
import UpdateDocumentTemplateMutation from "~/gql/documentTemplate/UpdateDocumentTemplate.gql";
import DeleteDocumentTemplatesMutation from "~/gql/documentTemplate/DeleteDocumentTemplates.gql";

export const documentTemplate = {
  /**
   * 書類テンプレート一覧を取得する
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectDocumentTemplatesAction(app, variables) {
    // GraphQLから書類テンプレート一覧を取得する
    return await apolloModel.query(app, SelectDocumentTemplatesQuery, variables);
  },

  /**
   * 書類テンプレート作成
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async createDocumentTemplateAction(app, variables) {
    // GraphQL書類テンプレート作成
    const data = await apolloModel.mutate(app, CreateDocumentTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 書類テンプレート更新
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async updateDocumentTemplateAction(app, variables) {
    // GraphQL書類テンプレート更新
    const data = await apolloModel.mutate(app, UpdateDocumentTemplateMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 書類テンプレート削除
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async deleteDocumentTemplatesAction(app, variables) {
    const data = await apolloModel.mutate(app, DeleteDocumentTemplatesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
