import {apolloModel} from "~/services/gql/vender/model";
import SelectAnnouncementsQuery from "~/gql/setting/announcement/SelectAnnouncements.gql";
import DeleteAnnouncementsMutation from "~/gql/setting/announcement/DeleteAnnouncements.gql";
import SelectAnnouncementQuery from "~/gql/setting/announcement/SelectAnnouncement.gql";
import CreateAnnouncementMutation from "~/gql/setting/announcement/CreateAnnouncement.gql";
import UpdateAnnouncementMutation from "~/gql/setting/announcement/UpdateAnnouncement.gql";

import constAnnouncement from "~/const/announcement";

export const announcement = {

  /**
   * Get List Announcement Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAnnouncementsAction(app, variables) {
    if (!variables.pageSize) {
      variables = Object.assign(variables, {pageSize: constAnnouncement.pagination.pageSize});
    }
    const data = await apolloModel.query(app, SelectAnnouncementsQuery, variables);
    return data;
  },

  /**
   * Select Announcement Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAnnouncementAction(app, variables) {
    // GraphQLお知らせ情報のデータ取得
    const data = await apolloModel.query(app, SelectAnnouncementQuery, variables);
    return data;
  },

  /**
   * Create Announcement Action
   * @param app
   * @param announcementInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createAnnouncementAction(app, announcementInput) {
    // GraphQLお知らせ情報設定リクエスト
    const variables = {createAnnouncementInput: announcementInput};
    const data = await apolloModel.mutate(app, CreateAnnouncementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Update Announcement Action
   * @param app
   * @param announcementInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async updateAnnouncementAction(app, announcementInput) {
    // GraphQLお知らせ情報更新リクエスト
    const variables = {updateAnnouncementInput: announcementInput};
    const data = await apolloModel.mutate(app, UpdateAnnouncementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Announcements Action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async deleteAnnouncementsAction(app, variables) {
    // お知らせ情報削除リクエスト
    return await apolloModel.mutate(app, DeleteAnnouncementsMutation, variables);
  },
}
