// 使用するモジュールのみimport
import {get, cloneDeep} from 'lodash'

export default function (ctx, inject) {
  inject('get', get);
  inject('cloneDeep', cloneDeep);
  inject('omitDeep', omitDeep);
}

const omitDeep = (obj, targetList) => {
  for (const key in obj) {
    const value = obj[key];
    const isObject = v => v !== null && typeof v === 'object';

    if (targetList.includes(key)) {
      delete obj[key];
    } else if (isObject(value)) {
      omitDeep(value, targetList);
    }
  }
}
