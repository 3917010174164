import {mapGetters} from "vuex";

export default {
  name: "GlobalGetters",
  computed: {
    ...mapGetters({
      systemMessage: 'app/systemMessage',
      loginUser: 'app/loginUser',
      shopMapList: 'app/shopMapList',
      staffMapList: 'app/staffMapList',
      headLoading: 'app/headLoading',
      staffLoading: 'app/staffLoading',
    }),
  },
}

