export default async function ({route, redirect}) {
  const excludeRouteNameList = ['property-property', 'privacy', 'message-line-registration', 'line-tracking'];
  if (excludeRouteNameList.some(routeName => route.name?.includes(routeName))) return;
  if (route.query['sso_code']) {
    localStorage.setItem('ags_sso_staff', route.query['sso_code']);
    const {sso_code, ...queryWithoutSSOCode} = route.query;
    return redirect({path: route.path, query: queryWithoutSSOCode});
  }
  const ssoCode = localStorage.getItem('ags_sso_staff');
  let path = route.path.replace('/', '');
  if (!ssoCode && (path === 'maisoku' || path.substring(0, path.indexOf('/')) === 'maisoku')) {
    window.location.replace(process.env.TRUST_LOGIN_MAISOKU_AUTH_URL);
  } else if (!ssoCode) {
    window.location.replace(process.env.TRUST_LOGIN_AGS_AUTH_URL);
  }
}

export async function getStaffMail() {
  return atob(localStorage.getItem('ags_sso_staff'));
}
