import {removeIrrelevantKeysInStorage} from "~/helper/common";

export default async ({app, store}) => {
  app.router.afterEach((to, from) => {
    const msg = store.getters['app/systemMessage'];

    // Remove irrelevent keys in storage to set Search conditions
    removeIrrelevantKeysInStorage(to, from);

    // メッセージ表示制御
    if (msg.isShow) {
      // 画面遷移時にも表示できるようにFlashを追加
      if (msg.isFlash) {
        store.dispatch('app/changeSystemMessage', {
          isShow: true,
          isFlash: false,
          type: msg.type,
          message: msg.message
        });
      } else {
        store.dispatch('app/changeSystemMessage', {isShow: false});
      }
    }
  });
};
