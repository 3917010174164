import {onDemand as OnDemand} from '../helper/onDemand';

export default (ctx, inject) => {
  // constructorに外部ライブラリのパスを渡す
  const loader = new OnDemand('https://static.line-scdn.net/liff/edge/2/sdk.js');

  inject('lineSDK', {
    // loadメソッドにcallbackを渡せば、JSの読み込み完了したら実行される。
    load(callback) {
      loader.load(callback);
    },
  });
};
