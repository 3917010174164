import { apolloModel } from '~/services/gql/vender/model';
import SelectMaisokuOutputQuery from '~/gql/maisokuOutput/SelectMaisokuOutput.gql';
import SelectMaisokuOutputsQuery from '~/gql/maisokuOutput/SelectMaisokuOutputs.gql';
import CreateMaisokuOutputMutation from '~/gql/maisokuOutput/CreateMaisokuOutput.gql';
import DeleteMaisokuOutputsMutation from '~/gql/maisokuOutput/DeleteMaisokuOutputs.gql';
import ExportMaisokuOutputMutation from '~/gql/maisokuOutput/ExportMaisokuOutput.gql';
import SelectSchoolListQuery from "~/gql/maisokuOutput/SelectSchoolList.gql";
import constMaisokuOutput from '~/const/maisokuOutput.js';

export const output = {
  /**
   * Select Maisoku output
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectOutput(app, variables) {
    // GraphQLからマイソク出力のデータ取得
    const data = await apolloModel.query(app, SelectMaisokuOutputQuery, variables);
    return data;
  },
  /**
   * Select Maisoku outputs
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectOutputs(app, variables) {
    // GraphQLから出力のデータ取得
    variables = Object.assign(variables, { pageSize: constMaisokuOutput.pagination.pageSize });
    const data = await apolloModel.query(app, SelectMaisokuOutputsQuery, variables);
    return data;
  },
  /**
   * Create Maisoku output
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async createOutput(app, variables) {
    // GraphQLへマイソク出力の登録リクエスト
    const data = await apolloModel.mutate(app, CreateMaisokuOutputMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
  /**
   * Delete Maisoku output
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async deleteOutputs(app, variables) {
    // GraphQLへマイソク出力の削除リクエスト
    const data = await apolloModel.mutate(app, DeleteMaisokuOutputsMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
  /**
   * Export Maisoku Output
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async exportOutput(app, variables) {
    // GraphQLへマイソク出力のエクスポートリクエスト
    const data = await apolloModel.mutate(app, ExportMaisokuOutputMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * List of 小学校区 and 中学校区
   *
   * @returns void
   * @param app
   * @param variables
   */
  async searchSchoolListAction(app, variables) {
    return await apolloModel.query(app, SelectSchoolListQuery, variables);
  },
};
