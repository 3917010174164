export const state = () => ({
  mailEditDataState: {
    mailEditClients: [],
    mailClientIds: [],
  },
})

export const getters = {
  /**
   * Mail Edit Data State Getters
   * @param state
   * @returns {*|string}
   */
  mailEditDataState: state => {
    return state.mailEditDataState;
  },
  mailEditClientsPagination: state => (page, pageSize) => {
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    return _.slice(state.mailEditDataState.mailEditClients, start, end);
  },
}

export const mutations = {
  /**
   * Set mail Edit Data State Mutations
   * @param state
   * @param data
   */
  setMailEditDataState(state, data) {
    if (!_.isEmpty(data.mailEditData)) state.mailEditDataState.mailEditData = data.mailEditData;
    if (!_.isEmpty(data.mailEditClients)) state.mailEditDataState.mailEditClients = _.uniqBy(_.concat(state.mailEditDataState.mailEditClients, data.mailEditClients), 'client')
    if (!_.isEmpty(data.mailClientIds)) state.mailEditDataState.mailClientIds = _.uniq(_.concat(state.mailEditDataState.mailClientIds, data.mailClientIds));
    if (!_.isEmpty(data.mailClientSearchCondition)) state.mailEditDataState.mailClientSearchCondition = data.mailClientSearchCondition;
    if (!_.isEmpty(data.mailEditPropertiesNumber)) state.mailEditDataState.mailEditPropertiesNumber = data.mailEditPropertiesNumber;
    if (!_.isEmpty(data.mailEditProperties)) state.mailEditDataState.mailEditProperties = data.mailEditProperties;
    if (!_.isEmpty(data.mailData)) state.mailEditDataState.mailData = data.mailData;
    if (_.isBoolean(data.isSelectedAllClients)) state.mailEditDataState.isSelectedAllClients = data.isSelectedAllClients;
  },

  /**
   * remove client id
   * @param state
   */
  removeMailClients(state) {
    state.mailEditDataState.mailEditClients = [];
    state.mailEditDataState.mailData = {};
    state.mailEditDataState.mailEditData = {};
    state.mailEditDataState.mailClientIds = [];
    state.mailEditDataState.mailClientSearchCondition = {};
    state.mailEditDataState.isSelectedAllClients = false;
  },

  /**
   * Clear Store
   * @param state
   */
  clearStore(state) {
    state.mailEditDataState = {
      mailEditData: {
        mailCarbonCopies: [],
        mailBlindCarbonCopies: [],
      },
      mailData: {},
      mailEditClients: [],
      isSelectedAllClients: false,
    };
  }
}
