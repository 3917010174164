import {apolloModel} from "~/services/gql/vender/model";
import SelectMonthlyInquiryQuery from "~/gql/response/SelectMonthlyInquiry.gql";
import SelectInquiriesQuery from "~/gql/response/SelectInquiries.gql";
import SelectMediumQuery from "~/gql/response/SelectMedium.gql";
import SelectDetailInquiryQuery from "~/gql/response/SelectDetailInquiry.gql";
import SelectCustomerListQuery from "~/gql/response/SelectCustomerList.gql";
import SelectSimilarCustomerListQuery from "~/gql/response/SelectSimilarCustomerList.gql";
import CreateInquiryMutation from "~/gql/response/CreateInquiry.gql";
import UpdateInquiryMutation from "~/gql/response/UpdateInquiry.gql";
import DeleteInquiryMutation from "~/gql/response/DeleteInquiry.gql";
import AddInquiryMutation from "~/gql/response/AddInquiry.gql";
import UpdateSoleInquiryMutation from "~/gql/response/UpdateSoleInquiry.gql";
import SelectProjectList from "~/gql/response/SelectProjectList.gql";
import SelectSimilarClientAddressQuery from "~/gql/response/SimilarCustomerRelation/SimilarClientAddress.gql";
import SelectSimilarClientHistoriesQuery from "~/gql/response/SimilarCustomerRelation/SimilarClientHistories.gql";
import SelectSimilarClientHousematesQuery from "~/gql/response/SimilarCustomerRelation/SimilarClientHousemates.gql";
import SelectSimilarSyokaiClientQuery from "~/gql/response/SimilarCustomerRelation/SimilarSyokaiClient.gql";
import SelectSimilarDesiredConditionsByCustomerQuery
  from "~/gql/response/SimilarCustomerRelation/SimilarDesiredConditionsByCustomer.gql";
import SelectSimilarDesiredConditionAreasQuery
  from "~/gql/response/SimilarCustomerRelation/ParentDesiredConditionRelation/SimilarDesiredConditionAreas.gql";
import SelectSimilarMatchingConditionQuery
  from "~/gql/response/SimilarCustomerRelation/ParentDesiredConditionRelation/SimilarMatchingCondition.gql";
import SelectSimilarParentDesiredConditionQuery
  from "~/gql/response/SimilarCustomerRelation/ParentDesiredConditionRelation/SimilarParentDesiredCondition.gql";
import {mergeData} from "~/helper/common";

export const response = {
  /**
   * 月別反響一覧のデータ取得処理
   * @returns void
   * @param app
   * @param date
   */
  async selectMonthlyInquiryAction(app, variables) {
    // GraphQLから月別反響一覧の初期データ取得
    const data = await apolloModel.query(app, SelectMonthlyInquiryQuery, variables);
    return data;
  },

  /**
   * 反響データ取得処理
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectInquiryListAction(app, variables) {
    // GraphQLから反響データ取得
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectInquiriesQuery, variables);
    return data;
  },

  /**
   * 媒体データ取得処理
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async selectMediumListAction(app, variables) {
    // GraphQLから媒体データ取得
    const data = await apolloModel.query(app, SelectMediumQuery, variables);

    return data;
  },

  /**
   * 反響の削除処理
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async deleteInquiryAction(app, variables) {
    // GraphQLへ反響削除リクエスト
    return await apolloModel.mutate(app, DeleteInquiryMutation, variables);
  },

  /**
   * 反響データ取得処理
   * @returns Promise<T>
   * @param app
   * @param variables {inquiry}
   */
  async initDetailInquiryAction(app, variables) {
    // GraphQLから反響データ取得
    let data = await apolloModel.query(app, SelectDetailInquiryQuery, variables);
    return data;
  },

  /**
   * 反響作成処理
   * @returns Promise<String|null> 反響ID
   * @param app
   * @param variables {inquiry, client, desiredCondition}
   */
  async createDetailInquiryAction(app, {inquiry, client, desiredCondition}) {
    // GraphQLへ反響作成リクエスト
    const variables = {inquiry, client, desiredCondition};

    //不要な項目削除 (希望条件)
    variables.desiredCondition.desiredConditionAreas.forEach(function (e) {
      e.desiredConditionAreaGakkous?.forEach(function (i) {
        delete i.category
      });
    });

    //不要な項目削除 (マッチング条件)
    variables.desiredCondition.matchingCondition.matchingConditionAreas.forEach(function (e) {
      e.matchingConditionAreaGakkous?.forEach(function (i) {
        delete i.category;
      });
    });

    const data = await apolloModel.mutate(app, CreateInquiryMutation, variables);
    return Promise.resolve(data.createInquiry.clientRelation?.client);
  },

  /**
   * 反響更新処理
   * @returns {Promise<*>}
   * @param app
   * @param variables {inquiry, client, desiredCondition}
   */
  async updateDetailInquiryAction(app, {inquiry, client, desiredCondition}) {
    // GraphQLへ反響更新リクエスト
    const variables = {inquiry, client, desiredCondition};
    // 案件更新で不要な項目削除
    // 反響部分
    delete variables.inquiry.shopRelation;
    delete variables.inquiry.medium;
    delete variables.inquiry.clientRelation;
    delete variables.inquiry.desiredCondition;
    delete variables.inquiry.created_at;
    delete variables.inquiry.admin_id;
    delete variables.inquiry.todohuken;
    delete variables.inquiry.todohukenRelation;
    delete variables.inquiry.sikucyoson;
    delete variables.inquiry.sikucyosonRelation;
    delete variables.inquiry.cyo;
    delete variables.inquiry.cyoRelation;
    delete variables.inquiry.cyomoku;
    delete variables.inquiry.cyomokuRelation;

    //顧客部分
    delete variables.client.location;
    delete variables.client.created_user;
    variables.client.clientHousemates.forEach(function (e) {
      delete e.__typename;
      delete e.todohukenRelation;
      delete e.sikucyosonRelation;
      delete e.cyoRelation;
      delete e.cyomokuRelation;
    })

    //案件部分
    delete variables.desiredCondition.created_user;
    delete variables.desiredCondition.created_at;
    delete variables.desiredCondition.deleted;
    delete variables.desiredCondition.updated_at;
    variables.desiredCondition.desiredConditionAreas.forEach(function (e) {
      delete e.__typename;
      delete e.todohukenRelation;
      delete e.sikucyosonRelation;
      delete e.startEkiRelation;
      delete e.endEkiRelation;
      delete e.ensenRelation;
      if (e.ensen_id === '') e.ensen_id = null;
      if (e.start_eki_id === '') e.start_eki_id = null;
      if (e.end_eki_id === '') e.end_eki_id = null;
      e.desiredConditionAreaCyos?.forEach(function (i) {
        delete i.__typename;
        delete i.cyoRelation;
      });
      e.desiredConditionAreaGakkous?.forEach(function (i) {
        delete i.__typename;
        delete i.surroundingRelation;
        delete i.category
      });
      e.desiredConditionAreaEkis?.forEach(function (i) {
        delete i.__typename;
        delete i.ekiRelation;
      });
    });
    variables.desiredCondition?.matchingCondition?.matchingConditionAreas?.forEach(function (e) {
      delete e.__typename;
      delete e.todohukenRelation;
      delete e.sikucyosonRelation;
      delete e.startEkiRelation;
      delete e.endEkiRelation;
      if (e.ensen_id === '') e.ensen_id = null;
      if (e.start_eki_id === '') e.start_eki_id = null;
      if (e.end_eki_id === '') e.end_eki_id = null;
      e.matchingConditionAreaCyos?.forEach(function (i) {
        delete i.__typename;
        delete i.cyoRelation;
      });
      e.matchingConditionAreaGakkous?.forEach(function (i) {
        delete i.__typename;
        delete i.surroundingRelation;
        delete i.category;
      });
      e.matchingConditionAreaEkis?.forEach(function (i) {
        delete i.__typename;
        delete i.ekiRelation;
      });
    });
    variables.desiredCondition?.desiredConditionFiles?.forEach(function (e) {
      delete e.created_at;
      delete e.created_user;
      delete e.kibou;
      delete e.updated_at;
      delete e.updated_user;
    });
    variables.desiredCondition?.desiredConditionMadoris?.forEach(function (e) {
      delete e.madoriRelation;
    });
    delete variables.desiredCondition.clientRelation;

    const data = await apolloModel.mutate(app, UpdateInquiryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 反響の単独追加処理
   * @returns Promise<String|null> 反響ID
   * @param app
   * @param variables {inquiry, client, desiredCondition}
   */
  async addInquiryAction(app, {inquiry, client, desiredCondition}) {
    // GraphQLへ反響作成リクエスト
    const variables = {inquiry, client, desiredCondition};
    delete variables.client?.updated_user;
    delete variables.desiredCondition?.updated_user;
    const data = await apolloModel.mutate(app, AddInquiryMutation, variables);
    return Promise.resolve(data.createInquiry.clientRelation?.client);
  },

  /**
   * 反響の単独更新処理
   * @returns Promise<String|null> 反響ID
   * @param app
   * @param variables {inquiry, client, desiredCondition}
   */
  async updateSoleInquiryAction(app, {inquiry, client}) {
    // GraphQLへ反響作成リクエスト
    const variables = {inquiry, client};
    //不要なデータ削除
    delete variables.inquiry.shopRelation;
    delete variables.inquiry.medium;
    delete variables.inquiry.clientRelation;
    delete variables.inquiry.desiredCondition;
    delete variables.inquiry.created_at;
    delete variables.inquiry.admin_id;
    delete variables.inquiry.todohuken;
    delete variables.inquiry.todohukenRelation;
    delete variables.inquiry.sikucyoson;
    delete variables.inquiry.sikucyosonRelation;
    delete variables.inquiry.cyo;
    delete variables.inquiry.cyoRelation;
    delete variables.inquiry.cyomoku;
    delete variables.inquiry.cyomokuRelation;
    const data = await apolloModel.mutate(app, UpdateSoleInquiryMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * 顧客リストの取得処理
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async customersAction(app, variables) {
    // GraphQLから顧客一覧のデータ取得
    const data = await apolloModel.query(app, SelectCustomerListQuery, variables);
    return data;
  },

  /**
   * 名寄せ顧客リストの取得処理
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async similarCustomersAction(app, variables) {
    // GraphQLから名寄せ顧客一覧のデータ取得
    const promiseData = [
      apolloModel.query(app, SelectSimilarCustomerListQuery, variables),
      apolloModel.query(app, SelectSimilarClientAddressQuery, variables),
      apolloModel.query(app, SelectSimilarClientHistoriesQuery, variables),
      apolloModel.query(app, SelectSimilarClientHousematesQuery, variables),
      apolloModel.query(app, SelectSimilarSyokaiClientQuery, variables),
      apolloModel.query(app, SelectSimilarDesiredConditionsByCustomerQuery, variables),
      apolloModel.query(app, SelectSimilarDesiredConditionAreasQuery, variables),
      apolloModel.query(app, SelectSimilarMatchingConditionQuery, variables),
      apolloModel.query(app, SelectSimilarParentDesiredConditionQuery, variables),
    ]
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses);
      })
      .catch(error => {
        throw error;
      });
  },

  /**
   * 顧客の持つ案件取得
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async initAddInquiryProjectAction(app, variables) {
    // GraphQLから顧客のデータ取得
    const data = await apolloModel.query(app, SelectProjectList, variables);
    return data;
  },
}
