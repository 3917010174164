import {apolloModel} from "~/services/gql/vender/model";
import SelectAchievementOutputsQuery from "~/gql/achievementOutput/SelectAchievementOutputs.gql";
import CreateAchievementOutputMutation from "~/gql/achievementOutput/CreateAchievementOutput.gql";
import DeleteAchievementOutputsMutation from "~/gql/achievementOutput/DeleteAchievementOutputs.gql";

import constAchievementOutput from "~/const/achievementOutput";

export const achievementOutput = {

  /**
   * Get List Achievement Output Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAchievementOutputsAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constAchievementOutput.pagination.pageSize});
    const data = await apolloModel.query(app, SelectAchievementOutputsQuery, variables);
    return data;
  },

  /**
   * Create Achievement Output Action
   * @param app
   * @param achievementOutputInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async createAchievementOutputAction(app, achievementOutputInput) {
    // GraphQL出力設定リクエスト
    const variables = {createAchievementOutputInput: achievementOutputInput};
    const data = await apolloModel.mutate(app, CreateAchievementOutputMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * Delete Achievement Outputs Action
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async deleteAchievementOutputsAction(app, variables) {
    // 出力削除リクエスト
    return await apolloModel.mutate(app, DeleteAchievementOutputsMutation, variables);
  },
}
