import constUser from "~/const/user";

export const state = () => ({
  loginUser: {name: null, mail: null, shop_user: null, shop: null, company: null, user_kind: null, isAgentUser: false, hqCompanyId: null},
  staffData: {name: null, mail: null, shop_user: null, shop: null, company: null},
  systemMessage: {
    isShow: false,
    isFlash: false,
    type: 'error',  // 「success」 or 「error」
    message: 'システムメッセージ',
  },
  shopList: [],
  shopData: {},
  shopDataFromKibou: {},
  staffList: [],
  propertyData: {},
  propertyDataContact: {},
  headLoading: true,
  staffLoading: true,
  propertyLoading: true,
})

export const getters = {
  loginUser: state => {
    return state.loginUser;
  },
  staffData: state => {
    return state.staffData;
  },
  systemMessage: state => {
    return state.systemMessage;
  },
  shopMapList: state => {
    return state.shopList.map(shop => {
      return {key: shop.shop, value: shop.name, mail: shop.mail_customer_contact, companyName: shop?.companyRelation?.name, company: shop?.company}
    });
  },
  shopData: state => {
    return state.shopData;
  },
  shopDataFromKibou: state => {
    return state.shopDataFromKibou;
  },
  staffMapList: state => {
    return state.staffList.map(staff => {
      return {key: staff.shop_user, value: staff.name, kana: staff.kana}
    });
  },
  propertyData: state => {
    return state.propertyData;
  },
  propertyDataContact: state => {
    return state.propertyDataContact;
  },
  headLoading: state => {
    return state.headLoading;
  },
  staffLoading: state => {
    return state.staffLoading;
  },
  propertyLoading: state => {
    return state.propertyLoading;
  },
}

export const mutations = {

  /**
   * systemMessageをセットする
   * @param state
   * @param systemMessage
   */
  setSystemMessage(state, systemMessage) {
    state.systemMessage = Object.assign(state.systemMessage, systemMessage);
  },

  /**
   * スタッフ情報・店舗一覧をセットする
   * @param state
   * @param shopList
   */
  setStaffData(state, {data, shop}) {
    state.shopList = data.shopUser.userShopRelation.map(userShop => userShop.shopRelation);
    state.loginUser.name = data.shopUser.name;
    state.loginUser.mail = data.shopUser.mail;
    state.loginUser.shop_user = data.shopUser.shop_user;
    if (shop) {
      state.shopData = data.shopUser.userShopRelation.find(userShop => userShop?.shopRelation?.shop === shop)?.shopRelation;
    } else {
      state.loginUser.shop = parseInt(data.shopUser.userShopRelation.find(userShop => userShop?.is_main === "1")?.shopRelation?.shop ?? shop);
      state.shopData = data.shopUser.userShopRelation[0]?.shopRelation;
    }
    if (data.shopUser.user_kind === constUser.user_kind.headquarter) {
      state.loginUser.hqCompanyId = data.shopUser.userShops[0]?.companyRelation?.company;
    }
    state.loginUser.company = state.shopData.company;
    state.loginUser.user_kind = data.shopUser.user_kind;
    state.loginUser.isAgentUser = state.loginUser.user_kind === constUser.user_kind.agent;
    state.loginUser.todohuken = state.shopData.todohuken;
    state.loginUser.sikucyoson = state.shopData.sikucyoson;
    state.headLoading = false;
    state.staffLoading = false;
  },

  /**
   * 店舗一覧をセットする
   * @param state
   * @param shopList
   */
  setShopList(state, {shopsByCompanyId}) {
    state.shopList = shopsByCompanyId;
    state.headLoading = false;
    state.staffLoading = false;
  },

  /**
   * スタッフ一覧をセットする
   * @param state
   * @param staffList
   */
  setStaffList(state, {shopUsersByShop, shopId, shopCompanyId}) {
    state.staffList = shopUsersByShop;
    state.loginUser.company = shopCompanyId;
    state.loginUser.shop = shopId;
    state.headLoading = false;
    state.staffLoading = false;
  },

  /**
   * Set staff loading
   * @param state
   * @param staffLoading
   */
  setStaffLoading(state, staffLoading) {
    state.staffLoading = staffLoading;
  },

  /**
   * Set head loading
   * @param state
   * @param headLoading
   */
  setHeadLoading(state, headLoading) {
    state.headLoading = headLoading;
  },

  /**
   * 希望条件から店舗情報をセットする
   * @param state
   * @param data
   */
  setShopDataFromKibou(state, data) {
    state.shopDataFromKibou = data.desiredCondition.shopRelation;
    state.shopDataFromKibou.kibou = data.desiredCondition.kibou;
    state.shopDataFromKibou.client = data.desiredCondition.client;
    state.shopDataFromKibou.mainShopUser = data.desiredCondition.mainShopUser;
    state.shopDataFromKibou.subShopUser = data.desiredCondition.subShopUser;
    state.staffData.name = data.desiredCondition.mainShopUser.name;
    state.staffData.mail = data.desiredCondition.mainShopUser.mail;
    state.staffData.shop_user = Number(data.desiredCondition.mainShopUser.shop_user);
    state.staffData.shop = Number(data.desiredCondition.shopRelation.shop);
    state.staffData.company = Number(data.desiredCondition.shopRelation.company);
    state.headLoading = false;
  },

  /**
   * 物件情報をセットする
   * @param state
   * @param data
   */
  setPropertyData(state, data) {
    state.propertyData = data;
    state.propertyLoading = false;
  },
  /**
   * Set property Data contact
   * @param state
   * @param dataContact
   */
  setPropertyDataContact(state, dataContact) {
    state.propertyDataContact = dataContact;
  },
  /**
   * set ShopData Empty
   * @param state
   */
  setShopDataEmpty(state) {
    state.shopDataFromKibou = {};
    state.headLoading = false;
  },
}

export const actions = {
  /**
   * システムメッセージの状態を変更
   * @param commit
   * @param data
   */
  changeSystemMessage({commit}, data) {
    if (!data.message) {
      data.message = "";
    }
    commit('setSystemMessage', data);
  },

  /**
   * スタッフ・店舗情報のセット
   * @param state
   * @param commit
   * @param {data, shop}
   */
  setStaffData({commit}, {data, shop}) {
    commit('setStaffData', {data: data, shop: shop});
  },

  /**
   * 担当店舗のセット
   * @param state
   * @param commit
   * @param data
   */
  setShopList({commit}, data) {
    commit('setShopList', data);
  },

  /**
   * 担当スタッフのセット
   * @param state
   * @param commit
   * @param data
   */
  setStaffList({commit}, data) {
    commit('setStaffList', data);
  },

  /**
   * Set staff loading
   * @param state
   * @param commit
   * @param staffLoading
   */
  setStaffLoading({commit}, staffLoading) {
    commit('setStaffLoading', staffLoading);
  },

  /**
   * Set head loading
   * @param state
   * @param commit
   * @param headLoading
   */
  setHeadLoading({commit}, headLoading) {
    commit('setHeadLoading', headLoading);
  },

  /**
   * 希望条件から店舗情報の取得
   * @param state
   * @param commit
   * @param data
   */
  setShopDataFromKibou({commit}, data) {
    commit('setShopDataFromKibou', data);
  },

  /**
   * 物件情報のセット
   * @param state
   * @param commit
   * @param data
   */
  setPropertyData({commit}, data) {
    commit('setPropertyData', data);
  },
  /**
   * set Property Data Contact
   * @param commit
   * @param dataContact
   */
  setPropertyDataContact({commit}, dataContact) {
    commit('setPropertyDataContact', dataContact);
  },
}
