import {apolloModel} from "~/services/gql/vender/model";
import SelectPropertyRangeQuery from "~/gql/setting/realEstateAgent/SelectPropertyRange.gql";
import UpdatePropertyRangeMutation from "~/gql/setting/realEstateAgent/UpdatePropertyRange.gql";

export const propertyRangeTorihikitaiyo = {
  /**
   * 不動産エージェント物件公開範囲取得
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectSettingAction(app, variables) {
    // GraphQL不動産エージェント物件公開範囲取得
    return await apolloModel.query(app, SelectPropertyRangeQuery, variables);
  },

  /**
   * 不動産エージェント物件公開範囲更新
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async updateSettingAction(app, variables) {
    // GraphQL不動産エージェント物件公開範囲更新
    const data = await apolloModel.mutate(app, UpdatePropertyRangeMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
