import {apolloModel} from "~/services/gql/vender/model";
import SelectCustomerHistoriesQuery from "~/gql/calendar/SelectCustomerHistories.gql";
import SelectCustomerHistoryQuery from "~/gql/calendar/SelectCustomerHistory.gql";

export const calendar = {
  /**
   * 顧客データ取得処理
   * @returns {Promise<void>}
   * @param app
   * @param variables
   */
  async selectCalendarCustomerHistoriesAction(app, variables) {
    // GraphQLから顧客データ取得
    const data = await apolloModel.query(app, SelectCustomerHistoriesQuery, variables);
    return data ? data : [];
  },

  /**
   * 顧客データ取得処理
   * @returns {Promise<void>}
   * @param app
   * @param variables
   */
  async selectCalendarCustomerHistoryAction(app, variables) {
    // GraphQLから顧客データ取得
    const data = await apolloModel.query(app, SelectCustomerHistoryQuery, variables);
    return data ? data : [];
  },
}
