import {apolloModel} from "~/services/gql/vender/model";
import SelectAdvertisesQuery from "~/gql/advertise/SelectAdvertises.gql";
import UpsertAdvertisesMutation from "~/gql/advertise/UpSertAdvertises.gql";
import constAdvertise from "~/const/advertise";

export const advertise = {

  /**
   * Get List Advertise Action
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAdvertisesAction(app, variables) {
    variables = Object.assign(variables, {pageSize: constAdvertise.pagination.pageSize});
    const data = await apolloModel.query(app, SelectAdvertisesQuery, variables);
    return data;
  },

  /**
   * Upsert Advertise Action
   * @param app
   * @param mediumCostInput
   * @returns {Promise<Promise<void>|Promise<never>>}
   */
  async upsertAdvertiseAction(app, mediumCostInput) {
    const variables = {mediumCostInput: mediumCostInput};
    const data = await apolloModel.mutate(app, UpsertAdvertisesMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  }
}
