import {apolloModel} from "~/services/gql/vender/model";
import CreateLineMutation from "~/gql/line/CreateLine.gql";

export const line = {
  /**
   * Create Line Action
   * @param app
   * @param line
   */
  async createLineAction(app, line) {
    // GraphQLへ反響更新リクエスト
    const variables = {createLineSend: line};
    const data = await apolloModel.mutate(app, CreateLineMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
