import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

const ls = new SecureLS({isCompression: false});

export default ({store}) => {
  createPersistedState({
    key: 'cms',
    paths: ['app.shopDataFromKibou', 'app.loginUser', 'app.shopList', 'app.staffList'],
    storage: {
      getItem: (key) => {
        return ls.get(key)
      },
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    }
  })(store)
}
